/**
 * Entry point for development and production PWA builds.
 */
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';
import App from './App';
import React from 'react';
import { history } from './utils/history';

/**
 * EXTENSIONS AND MODES
 * =================
 * pluginImports.js is dynamically generated from extension and mode
 * configuration at build time.
 *
 * pluginImports.js imports all of the modes and extensions and adds them
 * to the window for processing.
 */
import { modes as defaultModes, extensions as defaultExtensions } from './pluginImports';
import loadDynamicConfig from './loadDynamicConfig';

// Function to extract Study Instance UID from URL parameters
function getStudyInstanceUIDFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('studyInstanceUID');
}

// Extract the Study Instance UID
const studyInstanceUID = getStudyInstanceUIDFromUrl();

loadDynamicConfig(window.config).then(config_json => {
  // Reset Dynamic config if defined
  if (config_json !== null) {
    window.config = config_json;
  }

  // Check if the Study Instance UID is available and update the config
  if (studyInstanceUID) {
    // Ensure the studyInstanceUIDs array is set
    if (!window.config.studyInstanceUIDs) {
      window.config.studyInstanceUIDs = [];
    }
    // Add the extracted UID to the config
    window.config.studyInstanceUIDs.push(studyInstanceUID);
  }

  /**
   * Combine our appConfiguration with installed extensions and modes.
   * In the future appConfiguration may contain modes added at runtime.
   */
  const appProps = {
    config: window ? window.config : {},
    defaultExtensions,
    defaultModes,
  };

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(React.createElement(App, appProps));
});

export { history };
